
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { AuthPayment } from '@/models/dto/AuthPayment'
import { friendlyDate } from '@/utils/time'
import { currencyFilter } from '@/utils/string'
import { EventBus } from '@/utils/event-bus'
import authPayment from '@/services/authPayment'

@Component({})
export default class VoidAuthorizationHoldSidebar extends Vue {
  @Prop({ type: Number, required: true }) readonly quoteId: number
  @Prop({ type: Object, required: true }) readonly authPayment: AuthPayment

  submitting = false

  close(): void {
    this.$store.dispatch('app/closeDialog')
  }

  get authPaymentCopy() {
    const { mask, type_label } = JSON.parse(this.authPayment?.meta)
    const cardInfo = `${type_label?.charAt(0)?.toUpperCase() + type_label?.slice(1)} *${mask}`
    const holdAmount = currencyFilter(this.authPayment?.amount)
    const authorizedOn = friendlyDate(this.authPayment?.createdOn)
    return `${cardInfo} was authorized for ${holdAmount} on ${authorizedOn}`
  }

  async voidQuoteAuthorizationHold(): Promise<void> {
    this.submitting = true
    try {
      await authPayment.voidAuthHold(this.quoteId)
      EventBus.$emit('refreshAuthPayment')
      this.submitting = false
      this.close()
    } catch (e) {
      console.error(e)
      await this.$store.dispatch(
        'app/showAlert',
        {
          type: 'error',
          message: 'Failed to Void Authorization Hold',
        }
      )
    }
    this.submitting = false
  }

}
